import React, {useState} from 'react';
import '../assets/css/style.css';
import axios from 'axios';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { settings } from '../config/settings';

const baseUrl = settings.API_URL + "auth/login";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border: 10px solid #78ECDB;
`;

export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errormessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [color] = useState("#ffffff");

    async function loginUser(username, password) {
        return axios.post(baseUrl, {username: username, password: password})
        .then(function(response){
            console.log(response);
            return response.data.response.access_token;
        })
        .catch(function(error){
            console.log(error);
            setErrorMessage('El usuario o la contraseña es incorrecta');
        })
    }
  
    const handleSubmit = async e => {
      e.preventDefault();
      const token = await loginUser(
        username,
        password
      );
      if(typeof token !== 'undefined'){
        setLoading(true);  
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        setTimeout(function(){ window.location.href="./"; }, 3000);
      }
    }
    

    return(
    <div>
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="auth-bg">
                    <span className="r"></span>
                    <span className="r s"></span>
                    <span className="r s"></span>
                    <span className="r"></span>
                </div>
                {loading ? <ClipLoader color={color} loading={loading} css={override} size={300} /> : 
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"></i>
                                </div>
                                <h3 className="mb-4">Login</h3>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="user" name="username" onChange={e => setUserName(e.target.value)} />
                                </div>
                                <div className="input-group mb-4">
                                    <input type="password" className="form-control" placeholder="password" name="password" onChange={e => setPassword(e.target.value)} />
                                </div>
                                <div style={{textAlign: "center", color: "red", transform: "translateY(-10px)"}}>
                                    {errormessage}
                                </div>
                                <button className="btn btn-primary shadow-2 mb-4" type="submit">Login</button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    </div>
    );

}

