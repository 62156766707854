import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
// import FloatingLabel from 'react-bootstrap/FloatingLabel'
import FloatingLabel from 'react-bootstrap-floating-label';
import { settings } from '../config/settings';
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";

const baseUrl = settings.API_URL + 'translation/upload';

function Dragdrop({ updateListing }) {
	const formData = new FormData();
	const [ files, setFile ] = useState([]);
	const [ name, setName ] = useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: '.xls,.xlsx',
		onDrop: (acceptedFiles) => {
			setFile(acceptedFiles.map((file) => Object.assign(file)));
		}
	});

	files.map((file) => formData.append('files', file, file.name));

	const mySubmitHandler = (event) => {
		event.preventDefault();
		UploadFile();
	};
	const handleNameChange = (e) => {
		setName(e.target.value);
	};
	const UploadFile = () => {
		const user = localStorage.getItem('token');
		formData.delete('name');
		formData.append('name', name);
		toast.info('Uploading data!', { autoClose: false });
		axios
			.post(baseUrl, formData, {
				headers: {
					Authorization: `Bearer ${user}`
				}
			})
			.then((response) => {
				// console.log(response.data);
				toast.dismiss();
				toast.success('Data Uploaded!', { autoClose: 3000 });

				//console.log('ZIP:', response.data.response);
				const blob = b64ToBlob(response.data.response, "application/zip");
        		fileSaver.saveAs(blob, `translations.zip`);

				return response;
			})
			.then(() => {
				setFile([]);
				updateListing();
			})
			.catch((error) => {
				console.error(error);
				if (error.response.status === 401) {
					toast.error('Unauthorized', { autoClose: 3000 });
					localStorage.removeItem('token');
					window.location.href = './';
				} else {
					toast.error('Error uploading data!', { autoClose: 3000 });
				}
			});
	};

	const clearFileList = (e) => {
		setFile([]);
	};

	return (
		<form className="container" onSubmit={mySubmitHandler}>
			{/* <input type="text" name='name' value={name} onChange={handleNameChange}/> */}
			{/* <FloatingLabel controlId="floatingInputGrid" label="Complete Update Name">
				<Form.Group className="m-0">
					<Form.Control type="text" name="name" ref={name} onChange={handleNameChange} />
				</Form.Group>
			</FloatingLabel> */}
			<input
				class="form-control mb-2"
				onChange={(event) => setName(event.target.value)}
				placeholder="Complete update name"
			/>
			<div {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<p style={{ textAlign: 'center', fontSize: '30px' }}>
					<i
						className="feather icon-download-cloud"
						style={{ fontSize: '50px', margin: '0 10px 0 0px' }}
					/>Drop files here
				</p>
			</div>
			<br />
			<aside>
				<h4>Files</h4>
				<ul>{files.map((file) => <div key={file.path}>{file.path}</div>)}</ul>
			</aside>
			<div style={{ textAlign: 'end' }}>
				{files.length > 0 && (
					<input type="button" value="Restart" className="btn btn-warning" onClick={clearFileList} />
				)}
				<input type="submit" value="Upload" className="btn btn-danger" />
			</div>
			<ToastContainer />
		</form>
	);
}

export default Dragdrop;
