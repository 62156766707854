import React, { useState } from 'react';
import Posts from './Posts.js';
import Pagination from './Pagination.js';

function History({ posts }) {
	const [ loading ] = useState(false);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ postsPerPage ] = useState(10);
	const [ active, setActive ] = useState(0);

	let titlePost = [];
	titlePost = Object.keys(posts).reverse();
	let contentsPost = [];
	contentsPost = Object.values(posts).reverse();

	//Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPostsTitle = titlePost.slice(indexOfFirstPost, indexOfLastPost);
	const currentPosts = contentsPost.slice(indexOfFirstPost, indexOfLastPost);

	//Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const hidden = (page) => {
		if (active !== 0 && active === page) {
			setActive(0);
		} else {
			setActive(page);
		}
	};

	return (
		<div>
			<Posts
				posts={currentPosts}
				postsTitle={currentPostsTitle}
				loading={loading}
				hidden={hidden}
				active={active}
			/>
			<br />
			<Pagination
				postsPerPage={postsPerPage}
				totalPosts={titlePost.length}
				paginate={paginate}
				currentPage={currentPage}
			/>
		</div>
	);
}

export default History;
