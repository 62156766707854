import React from 'react';
import '../assets/css/style.css';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
    const pageNumber = [];

    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
        pageNumber.push(i);
    }
    return(
         <nav className="Page navigation example">
            <ul className="pagination" style={{justifyContent: "center", marginBottom: "30px"}}>
                {pageNumber.map(number =>(
                    <li key={number} className="page-item">
                        <div onClick={() => paginate(number)} className="page-link" style={(number === currentPage) ? {background: "#0a4f29", color: "#FFFFFF"} : {background:""}}>
                            {number}
                        </div>
                    </li>
                ))}
            </ul>      
         </nav>
        
    )
}

export default Pagination;