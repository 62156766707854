import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { settings } from '../config/settings';

const baseUrl = settings.API_URL + "translation/re-process";
const fileUrl = settings.FILE_URL;

function Posts({ posts, postsTitle, loading, hidden, active }) {
	if (loading) {
		return <h2>Loading...</h2>;
	}
	console.log('posts:', postsTitle);
	var TitleDate = [];
	var titleName = [];
	for (var i = 0; i < postsTitle.length; i++) {
		var date = new Date(parseInt(postsTitle[i].split('-')[0]));
		var name = '';

		if(postsTitle[i].split('-').length > 1){// && postsTitle[i].split('-')[1] !== null && postsTitle[i].split('-')[1] !== ''){
			name = postsTitle[i].split('-')[1];
			titleName.push(name);
		}else{
			titleName.push('no name');
		}

		let minutes = date.getMinutes();
		let hours = date.getHours();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		TitleDate.push(
			day +
				'/' +
				month +
				'/' +
				year 
				+
				' ' +
				((hours < 10 ? '0' : '') + hours) +
				':' +
				((minutes < 10 ? '0' : '') + minutes)
				
				// posts[i]?.length
		);
	}

	const uploadExcel = (date, index) => {
		const token = localStorage.getItem('token');
		console.log(postsTitle[index]);
		axios
			.post(
				baseUrl,
				{ dateNumbParam: postsTitle[index] },
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)
			.then((response) => {
				// console.log(response);
				toast.success('Uploading data!', { autoClose: 3000 });
				return response;
			})
			.then((response) => {
				// console.log(response);
			})
			.catch((error) => {
				// console.error(error);
				if (error.response.status === 401) {
					toast.error('Unauthorized', { autoClose: 3000 });
					localStorage.removeItem('token');
					window.location.href = './';
				} else {
					toast.error('Error uploading data!', { autoClose: 3000 });
				}
			});
	};

	const downloadExcel = (date, index) => {
		var i = 0;
		var totalExcel = setInterval(function() {
			if (i < posts[index].length) {
				var element = document.createElement('a');
				var link = fileUrl + date + '/' + posts[index][i].name;
				element.setAttribute('href', link);

				element.style.display = 'none';
				document.body.appendChild(element);

				element.click();

				document.body.removeChild(element);
				i++;
			} else {
				clearInterval(totalExcel);
			}
		}, 500);
	};

	const content = (
		<ul className='history-table' style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
			{postsTitle.map((item, index) => (
				<li className='history-row' key={item} style={{ padding: '5px' }}>
					<div className="row">
						<div className="col-9">
							<i
								onClick={() => hidden(item)}
								className={active === item ? 'feather icon-minus' : 'feather icon-plus'}
								style={{ margin: '0px 5px 0px 0px' }}
							/>
						
							{titleName[index]}
						</div>
						<div className="col-3">
							{TitleDate[index]}
							
							
							<i
								className="feather icon-upload"
								style={{ float: 'right', padding: '3px', cursor: 'pointer' }}
								onClick={() => uploadExcel(item, index)}
							/>
							<i
								className="feather icon-download"
								style={{ float: 'right', padding: '3px', cursor: 'pointer' }}
								onClick={() => downloadExcel(item, index)}
							/>
							<i
								className="feather icon-file-text"
								style={{ float: 'right', padding: '3px 20px 3px 3px', cursor: 'pointer' }}>
							</i>
							<span style={{ float: 'right',}}>{posts[index]?.length}</span>
						</div>
					</div>
					<ul key={index} style={active === item ? { display: '' } : { display: 'none' }}>
						{posts[index].map((data, i) => (
							<li
								key={i}
								style={{ 
									backgroundColor: index % 2 === 0 ?'#e0e0e0':'white',
									display: 'inline-block',
									padding: '0px 5px',
									margin: '0px 5px 5px 0px'
								}}
							>
								<i className="feather icon-file" style={{ margin: '0px 5px 0px 0px' }} />
								{data.name}
								<a href={fileUrl + item + '/' + data.name} download>
									<i className="feather icon-download" style={{ margin: '0px 0px 0px 5px' }} />
								</a>
							</li>
						))}
					</ul>
				</li>
			))}
			<ToastContainer />
		</ul>
	);

	return <div>{content}</div>;
}

export default Posts;
