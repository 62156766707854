import ListCountries from './ListCountries';

function Countries() {
    return(
    <div className="col">
        <div className="card user-list">
            <div className="card-header">
                <h5>Countries</h5>
            </div>
            <div className="card-block">
                <ListCountries/>
            </div>
        </div>
    </div>        
    )    
}

export default Countries;