//import axios from 'axios';
import axios from 'axios';
import React, {useEffect, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { settings } from '../config/settings';

const baseUrl = settings.API_URL + "translation/by-country?code=";
const fileUrl = settings.FILE_URL;

function ListCountries() {
    const [country, setCountry] = useState([]);

    useEffect(() => {
        fetch("data.json")
          .then(res => res.json())
          .then(
            (result) => {
              setCountry(result.countries);
              // console.log(result.countries);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log(error);
            }
          )
      }, [])

    // const downloadExcel = (item, index) => {
    //   console.log("item ", item.country);
    // }  

    const downloadExcel = async (countryData, index) => {
      const token = localStorage.getItem('token');      
      const result = await axios.get(baseUrl + countryData?.Abbreviation, {
        headers: {
          'Authorization' : `Bearer ${token}`,
        }
      })
      if(result.data.response) {
        var element = document.createElement('a');
        var link = fileUrl + result.data.response;
        element.setAttribute('href', link);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        toast.success("Download success!", {autoClose:3000});
      } else {
        toast.info("Xlsx not found", {autoClose:3000});
      }
  }

    return(
     <div>
      <div className="flex-container">
        {country.map((item, index) => (
          <button key={index} onClick={() => downloadExcel(item, index)}>
            {item.country}<i className="feather icon-download" style={{float: "right", padding: "3px"}}></i>
          </button>
        ))}
      </div>
      <ToastContainer />
     </div>
    )
}

export default ListCountries;
