import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Login from '../pages/Login';
//import Home from '../pages/Home';
import Dashboard from '../component/Dashboard';
import Countries from '../component/Countries';

function Routes() {
	const token = localStorage.getItem('token');

	if (!token) {
		return <Login />;
	}

	const Signout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('username');
		window.location.href = './';
	};

	const Username = () =>{
		return localStorage.getItem('username');
	}
	return (
		<Router>
			<div className="main-body">
				<div className="row" style={{ margin: '0px', minHeight: '100vh' }}>
					<div
						className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"
						style={{
							margin: '0px',
							background: '#3F4D67',
							padding: '15px',
							textAlign: 'center',
							boxShadow: ' 6px 6px 8px 0 rgba(0, 0, 0, 0.2), 8px 8px 20px 0 rgba(0, 0, 0, 0.19)'
						}}
					>
						<div className="navbar-brand header-logo">
							<div className="b-brand">
								<div className="b-bg">
									<i className="feather icon-trending-up" />
								</div>
								<span className="b-title">Hpe Education</span>
							</div>
						</div>

						<ul
							className="nav flex-column nav-pills"
							id="v-pills-tab"
							role="tablist"
							aria-orientation="vertical"
							style={{ background: '#3F4D67' }}
						>
							<li>
								<Link to="/">
									<div className="nav-link">
										<span className="pcoded-micon">
											<i
												className="feather icon-home"
												style={{ padding: '0px 8px 0px 0px', color: 'white' }}
											/>
										</span>
										<span className="pcoded-mtext" style={{ color: 'white' }}>
											Updates
										</span>
									</div>
								</Link>
							</li>
							<li className="countries">
								<Link to="/countries">
									<div className="nav-link">
										<span className="pcoded-micon">
											<i
												className="feather icon-map"
												style={{ padding: '0px 8px 0px 0px', color: 'white' }}
											/>
										</span>
										<span className="pcoded-mtext" style={{ color: 'white' }}>
											Countries
										</span>
									</div>
								</Link>
							</li>
						</ul>
					</div>

					<div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10" style={{ margin: '0px' }}>
						<div className="row" style={{ margin: '0px', flexDirection: 'row-reverse' }}>
							<button
								className="btn btn-danger"
								onClick={() => Signout()}
								style={{ margin: '15px 0 15px 0' }}
							>
								Sign out
							</button>
							<h5 className='logged-user' style={{marginTop:'20px', marginRight:'50px'}}>{Username()}</h5>
						</div>
						<div className="tab-content" id="v-pills-tabContent" style={{ minHeight: '100vh' }}>
							<div className="main-body">
								<div className="page-wrapper">
									<Switch>
										<Route exact path="/">
											<Dashboard />
										</Route>
										<Route path="/countries">
											<Countries />
										</Route>
									</Switch>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Router>
	);
}

export default Routes;
