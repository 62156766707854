import React,{useState, useEffect} from 'react';
import History from './History';
import Dragdrop from './Dragdrop';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { settings } from '../config/settings';

const baseUrl = settings.API_URL + "translation/list";

function Dashboard() {
  const [updateList, setupdateList] = useState(0);
  const [posts, setPosts] = useState([]);

  useEffect(()=>{
    const token = localStorage.getItem('token');
    const getData=()=>{
      axios.get(baseUrl, {
        headers: {
          'Authorization' : `Bearer ${token}`,
        }
      }).then(response => {
        //console.log(response.data.response);
        return response.data.response;
      }).then(response => {
        //console.log(response);
        setPosts(response);
      }).catch((error) => {
        console.error(error.response);
        if(error.response.status === 401) {
          toast.error("Unauthorized", {autoClose:3000});
          localStorage.removeItem('token');
          window.location.href="./";
        }
      });
    }
    getData();
  },[updateList])

  const updateListing = () => {
    setupdateList(updateList+1);
  }

  return (
     <div className="col">
          <div className="card user-list">
            <div className="card-header">
              <h5>Upload</h5>
            </div>
            <div className="card-block">
              <Dragdrop updateListing={updateListing}/>
            </div>
          </div>
        
          <div className="card user-list">
            <div className="card-header">
              <h5>History</h5>
            </div>
            <div className="row" style={{border: '1px solid rgba(42, 42, 42, 0.05)', background: 'rgba(204, 204, 204, 0.15)', marginRight:'0px', marginLeft:'0px'}}>
              <div className="col-sm-8 ml-5 mt-2"><p>Update Name</p></div>
              <div className="col-sm-2 ml-5 mt-2"><p>Date</p></div>
            </div>
            <div className="card-block pt-2" style={{paddingRight:'0px', paddingLeft:'0px'}}>
              <History posts={posts}/>
            </div>
          </div>
          <ToastContainer/>
    </div>       
  );
}

export default Dashboard;
